import io from 'socket.io-client';

export const api_path =
  // process.env.NODE_ENV === 'production' ? 'http://localhost:8089' : 'http://localhost:8089';
  process.env.NODE_ENV === 'production' ? 'https://chat.zieltech.me' : 'https://chat.zieltech.me';

export const socket = io(api_path, {
  autoConnect: false,
});

export const DEPARTMENTS = {
  5519983706262: [
    {
      label: 'Pessoal',
      value: 'Pessoal',
    },
    {
      label: 'Imobiliária Braido',
      value: 'Imobiliária Braido',
    },
  ],
  5519936180912: [
    {
      label: 'Comercial',
      value: 'Comercial',
    },
    {
      label: 'Suporte',
      value: 'Suporte',
    },
  ],
  551936522430: [
    {
      label: 'Locações',
      value: 'Locações',
    },
    {
      label: 'Vendas',
      value: 'Vendas',
    },
    {
      label: 'Financeiro',
      value: 'Financeiro',
    },
    {
      label: 'Manutenções',
      value: 'Manutenções',
    },
    {
      label: 'Renovações',
      value: 'Renovações',
    },
    {
      label: 'Desocupações',
      value: 'Desocupações',
    },
    {
      label: 'Jurídico',
      value: 'Jurídico',
    },
  ],
  5519997887358: [
    {
      label: 'Locações',
      value: 'Locações',
    },
    {
      label: 'Vendas',
      value: 'Vendas',
    },
    {
      label: 'Financeiro',
      value: 'Financeiro',
    },
    {
      label: 'Manutenções',
      value: 'Manutenções',
    },
    {
      label: 'Renovações',
      value: 'Renovações',
    },
    {
      label: 'Desocupações',
      value: 'Desocupações',
    },
    {
      label: 'Jurídico',
      value: 'Jurídico',
    },
  ],
  551936232893: [
    {
      label: 'SJBV - Locações',
      value: 'SJBV - Locações',
    },
    {
      label: 'SJBV - Vendas',
      value: 'SJBV - Vendas',
    },
    {
      label: 'SJBV - Financeiro',
      value: 'SJBV - Financeiro',
    },
    {
      label: 'SJBV - Manutenções',
      value: 'SJBV - Manutenções',
    },
    {
      label: 'SJBV - Renovações',
      value: 'SJBV - Renovações',
    },
    {
      label: 'SJBV - Desocupações',
      value: 'SJBV - Desocupações',
    },
    {
      label: 'SJBV - Jurídico',
      value: 'SJBV - Jurídico',
    },
  ],
  5519989006243: [
    {
      label: 'SJBV - Locações',
      value: 'SJBV - Locações',
    },
    {
      label: 'SJBV - Vendas',
      value: 'SJBV - Vendas',
    },
    {
      label: 'SJBV - Financeiro',
      value: 'SJBV - Financeiro',
    },
    {
      label: 'SJBV - Manutenções',
      value: 'SJBV - Manutenções',
    },
    {
      label: 'SJBV - Renovações',
      value: 'SJBV - Renovações',
    },
    {
      label: 'SJBV - Desocupações',
      value: 'SJBV - Desocupações',
    },
    {
      label: 'SJBV - Jurídico',
      value: 'SJBV - Jurídico',
    },
  ],
  5519998646993: [
    {
      label: 'SJBV - Locações',
      value: 'SJBV - Locações',
    },
    {
      label: 'SJBV - Vendas',
      value: 'SJBV - Vendas',
    },
    {
      label: 'SJBV - Financeiro',
      value: 'SJBV - Financeiro',
    },
    {
      label: 'SJBV - Manutenções',
      value: 'SJBV - Manutenções',
    },
    {
      label: 'SJBV - Renovações',
      value: 'SJBV - Renovações',
    },
    {
      label: 'SJBV - Desocupações',
      value: 'SJBV - Desocupações',
    },
    {
      label: 'SJBV - Jurídico',
      value: 'SJBV - Jurídico',
    },
  ],
  551936522507: [
    {
      label: 'Compras',
      value: 'Compras',
    },
    {
      label: 'Financeiro',
      value: 'Financeiro',
    },
    {
      label: 'Pedidos em Andamento',
      value: 'Pedidos em Andamento',
    },
    {
      label: 'Trocas e Devoluções',
      value: 'Trocas e Devoluções',
    },
    {
      label: 'Locação de Equipamento',
      value: 'Locação de Equipamento',
    },
  ],
};
